@font-face {
  font-family: "PT Mono";
  src: local("PT Mono"),
    url("../assets/fonts/PTMono-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.monospace {
  font-family: "PT Mono";
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  margin: -3px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
  position: absolute;
  pointer-events: none;
}
